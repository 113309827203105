import { h, render } from "preact";

import { loadRellax } from "./interface-utilities";

// import { pageScroll, moveDown } from "./libraries/page-scroll";
import { handleHeroDown, loadTruncables } from "./handlers";

import { NavBar } from "./apps/navbar";
import { Footer } from "./apps/footer";
import { CookieBannerApp } from "./apps/cookie-banner-app";
import { ReviewsApp } from "./apps/reviews-app";
// import { RestaurantsApp } from "./apps/restaurants-app";

render(<NavBar actived="index" />, document.getElementById('js-navbar-app'));
render(<Footer />, document.getElementById('js-footer-app'));
render(<CookieBannerApp />, document.getElementById('js-cookie-banner-app'));
render(<ReviewsApp />, document.getElementById('js-reviews-app'));
// render(<RestaurantsApp />, document.getElementById('js-restaurants-app'));

window.onload = function() {
  document.getElementById('js-loooader-app').classList.add('hidden');
  document.body.classList.add('loaded');
}

window.addEventListener('resize', function() {
  loadRellax();
});

document.getElementById('js-hero-down').addEventListener('click', handleHeroDown);

// if (process.env.NODE_ENV === "production") {
  // cache all assets if browser supports serviceworker
//   if ("serviceWorker" in navigator && location.protocol === "https:") {
//     navigator.serviceWorker.register("/sw.js");
//   }
// }

if (module.hot) {
  module.hot.accept()
}